<template>
  <DelayHydration>
    <component
      :is="frontendCeLayout"
      :uid="uid"
      :index="index"
      :appearance="appearance"
      :header="header"
      :subheader="subheader"
      :header-layout="headerLayout"
      :header-size="headerSize"
      :header-position="headerPosition"
      :header-link="headerLink"
      :bodytext="bodytext"
      :button="button"
      :gallery="gallery"
      :lang="currentLocale"
      class="t3-ce-text-pic"
    />
  </DelayHydration>
</template>

<script setup lang="ts">
import { computed, defineAsyncComponent } from 'vue'

import { useButton, useCurrentLocale } from '#imports'

import type { T3CeTextGalleryProps } from '~/types'

const props = withDefaults(defineProps<T3CeTextGalleryProps>(), {
  header: '',
  subheader: '',
  headerLayout: 1,
  headerSize: 1,
  headerPosition: '',
  headerLink: '',
  bodytext: '',
  buttonTitle: '',
  buttonColor: 'solid-default',
  buttonSize: 'large',
  buttonWrap: false,
  arrowButton: false,
  buttonLink: ''
})

const currentLocale = useCurrentLocale()
const button = useButton(props)

const TextWithIcons = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeTextpic/TextWithIcons')
)
const ImageGrid = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeTextpic/ImageGrid')
)
const ImageSlider = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeTextpic/ImageSlider')
)
const ImageCarousel = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeTextpic/ImageCarousel')
)
const LabeledIcons = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeTextpic/LabeledIcons')
)
const FeatureWithCarousel = defineAsyncComponent(
  () => import('~ui/components/T3Ce/T3CeTextpic/FeatureWithCarousel')
)

const frontendCeLayout = computed(() => {
  switch (props.appearance?.layout) {
    case 'imageGrid':
      return ImageGrid
    case 'imageSlider':
      return ImageSlider
    case 'imageCarousel':
      return ImageCarousel
    case 'labeledIcons':
      return LabeledIcons
    case 'featureWithCarousel':
      return FeatureWithCarousel
    default:
      return TextWithIcons
  }
})
</script>
